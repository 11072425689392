export default {
  title: 'Documentation',
  section_1: {
    title: 'FDES mères',
    documents: [
      {
        name: 'Caillebotis pressés',
        img: 'doc1.png',
        url: ''
      },
      {
        name: 'Caillebotis électroforgés',
        img: 'doc2.png',
        url: ''
      },
      {
        name: 'Sols industriels',
        img: 'doc3.png',
        url: ''
      },
    ],
  },
  section_2: {
    title: 'Catalogue des produits Meiser',
    link: {
      text: 'Catalogue des produits',
      url: 'https://www.meiser.de/fr/download-center.html',
    },
  },
};
